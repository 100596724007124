<script lang="ts">
    import { getContext } from 'svelte';
    import { Button } from '$lib/components/ui/button';
    import { Input } from '$lib/components/ui/input';
    import { Label } from '$lib/components/ui/label';
    import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '$lib/components/ui/select';
    import * as Dialog from '$lib/components/ui/dialog';
    import { Calendar } from '$lib/components/ui/calendar';
    import CalendarIcon from 'lucide-svelte/icons/calendar';
    import { cn } from '$lib/utils';
    import * as Table from '$lib/components/ui/table';
    import * as Popover from '$lib/components/ui/popover';
    import { DateFormatter, type DateValue, getLocalTimeZone, parseDate, today } from '@internationalized/date';

    const settingsContext = getContext('settingsContext');
    const { authUser, data } = settingsContext;

    interface Referral {
        id: string;
        used_by: {
            id: string;
            name: string;
            username: string;
        };
        created_at: string;
        earned: number;
    }

    const df = new DateFormatter('en-US', {
        dateStyle: 'long',
    });

    function getBaseUrl() {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    $: referralLink = (() => {
        switch (data.referrals_default_link_page) {
            case 'profile':
                return `${getBaseUrl()}/${authUser.username}?ref=${authUser.referral_code}`;
            case 'home':
                return `${getBaseUrl()}?ref=${authUser.referral_code}`;
            case 'register':
                return `${getBaseUrl()}/register?ref=${authUser.referral_code}`;
            default:
                return '';
        }
    })();

    function copyReferralLink() {
        navigator.clipboard
            .writeText(referralLink)
            .then(() => {
                launchToast('success', 'Success', 'Referral link copied to clipboard');
            })
            .catch((error) => {
                console.error('Failed to copy: ', error);
                launchToast('danger', 'Error', 'Failed to copy referral link');
            });
    }

    let selectedPriceType = '';
    let selectedPrice = 0;
    let selectedValidity: DateValue | undefined = undefined;
    let selectedReferral: Referral | null = null;

    function openPriceModal(referral: Referral) {
        selectedReferral = referral;
        selectedPriceType = '';
        selectedPrice = 0;
        selectedValidity = undefined;
    }

    function validateForm(): string | null {
        if (!selectedPriceType) {
            return 'Please select a price type';
        }
        if (selectedPrice <= 0) {
            return 'Please enter a valid price';
        }
        if (!selectedValidity) {
            return 'Please select a validity date';
        }
        return null;
    }

    async function submitPrice() {
        const validationError = validateForm();
        if (validationError) {
            launchToast('danger', 'Error', validationError);
            return;
        }

        try {
            const response = await fetch('/api/store-referral-sell-price', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: selectedReferral!.used_by.id,
                    type: selectedPriceType,
                    price: selectedPrice,
                    validity: selectedValidity!.toString(),
                }),
            });

            if (response.ok) {
                launchToast('success', 'Success', 'Referral listed for sale');
                selectedReferral = null;
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to list referral');
            }
        } catch (error) {
            console.error('Error:', error);
            launchToast('danger', 'Error', error instanceof Error ? error.message : 'Failed to list referral for sale');
        }
    }

    function formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString();
    }

    function formatCurrency(amount: number): string {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    }
</script>

<!-- Rest of the component remains the same -->

<Dialog.Root open="{selectedReferral !== null}" onOpenChange="{(open) => !open && (selectedReferral = null)}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Set price for {selectedReferral?.used_by.name}</Dialog.Title>
        </Dialog.Header>
        <form on:submit|preventDefault="{submitPrice}" class="space-y-4">
            <div class="space-y-4">
                <div class="space-y-2">
                    <Label for="price_type">Price Type</Label>
                    <Select bind:value="{selectedPriceType}">
                        <SelectTrigger>
                            <SelectValue placeholder="Select Price Type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="auction">Auction</SelectItem>
                            <SelectItem value="static">Static</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div class="space-y-2">
                    <Label for="price">
                        {selectedPriceType === 'auction' ? 'Starting Price' : 'Price'}
                    </Label>
                    <Input id="price" type="number" bind:value="{selectedPrice}" min="0.01" step="0.01" required />
                </div>
                <div class="space-y-2">
                    <Label for="validity">Validity</Label>
                    <Popover.Root>
                        <Popover.Trigger asChild let:builder>
                            <Button
                                variant="outline"
                                class="{cn(
                                    'w-full justify-start text-left font-normal',
                                    !selectedValidity && 'text-muted-foreground'
                                )}"
                                builders="{[builder]}"
                            >
                                <CalendarIcon class="mr-2 h-4 w-4" />
                                {selectedValidity
                                    ? df.format(selectedValidity.toDate(getLocalTimeZone()))
                                    : 'Pick a date'}
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content class="w-auto p-0">
                            <Calendar
                                bind:value="{selectedValidity}"
                                initialFocus
                                minDate="{today(getLocalTimeZone())}"
                            />
                        </Popover.Content>
                    </Popover.Root>
                </div>
            </div>
            <Dialog.Footer>
                <Button type="submit" variant="default">Submit</Button>
            </Dialog.Footer>
        </form>
    </Dialog.Content>
</Dialog.Root>
